import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { calculateTimeLeft } from "src/utils";
import { getAPIHandler } from "src/ApiConfig/service";
import toast from "react-hot-toast";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = accessToken;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [isProfileUpdating, setIsProfileUpdating] = useState(false);
  const [isDashboardUpdating, setIsDashboardUpdating] = useState(false);

  const getProfileDataApi = async (source) => {
    try {
      setUserData({});
      setIsProfileUpdating(true);
      const response = await getAPIHandler({
        endPoint: "myProfile",
        source: source,
      });
      console.log("checkresponse", response);
      if (response?.status === 200) {
        const data = response?.data?.data;
        console.log("profile data", data);
        setUserData(data);
        // setAdminAddress
        setIsProfileUpdating(false);
      }
      if (response?.status === 404) {
        handleLogout("You have been logout successfully!");
      }
      if (response?.status === 401) {
        handleLogout("You have been logout successfully!");
      }
      setIsProfileUpdating(false);
    } catch (error) {
      setIsProfileUpdating(false);
      if (error?.response?.status === 404) {
        handleLogout("You have been logout successfully!");
      } else if (error?.response?.status === 401) {
        handleLogout("You have been logout successfully!");
      }
    }
  };
  const handleLogout = (message) => {
    if (message) {
      toast.success(message);
    }
    window.sessionStorage.removeItem("token");
    window.localStorage.removeItem("packman");
    setIsLogin(false);
    setSession(null);
    window.open("/", "_self");
  };

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (window.sessionStorage.getItem("token")) {
      getProfileDataApi(source);
    }
    return () => {
      source.cancel();
    };
  }, [window.sessionStorage.getItem("token")]);
  let data = {
    adminAddress: userData.adminWallet,
    userLoggedIn: isLogin,
    userData,
    isProfileUpdating,
    timeLeft,
    isDashboardUpdating,
    dashboardData,
    handleLogout: (mess) => handleLogout(mess),
    setIsDashboardUpdating,
    setIsProfileUpdating,
    setEndtime,
    setDashboardData,
    userLogIn: (type, data) => {
      setIsLogin(type);
      setSession(data);
    },
    getProfileDataApi: (data) => getProfileDataApi(data),
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
