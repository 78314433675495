import { lazy } from "react";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    element: lazy(() => import("src/views/pages/Auth/Login")),
  },

  {
    guard: true,
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Dashboard/Dash")),
  },
  {
    guard: true,
    exact: true,
    path: "/fee-management",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/FeeManagement")),
  },
  {
    guard: true,
    exact: true,
    path: "/sub-admin-management",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/SubAdminManagement")),
  },
  {
    guard: true,
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/UserManagement")),
  },
  {
    guard: true,
    path: "/add-sub-admin",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/SubAdminManagement/AddSubAdmin")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/kyc-management",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/KycManagement/KycListing")),
  },

  {
    guard: true,
    exact: true,
    path: "/view-kyc-details",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/KycManagement/ViewKycDetails")),
  },

  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    element: lazy(() => import("src/views/pages/Auth/Forget")),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    element: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },

  {
    exact: true,
    guard: true,
    path: "/change-password",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    element: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    guard: true,
    exact: true,
    path: "/user-details",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/UserManagement/Listing")),
  },
  {
    guard: true,
    exact: true,
    path: "/wallet-listing",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/WalletManagement/WalletListing")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/wallet-trans-details",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/WalletManagement/TransactionDetails")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/transaction-listing",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/TransactionManagement/TransactionListing")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/reward-management",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/RewardManagement/RewardManagement")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/reward-settings",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/RewardManagement/RewardSettings")
    ),
  },
  // {
  //   guard: true,
  //   exact: true,
  //   path: "/allocate-funds",
  //   layout: DashboardLayout,
  //   element: lazy(() =>
  //     import("src/views/pages/RewardManagement/AllocateFund")
  //   ),
  // },
  {
    guard: true,
    exact: true,
    path: "/ticket-management",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/TicketManagement/TicketManagement")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/pending-ticket",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/TicketManagement/PendingDetails")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/resolved-ticket",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/TicketManagement/ResolvedDetails")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/static-management",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/Static")),
  },
  {
    guard: true,
    exact: true,
    path: "/edit-static-content/:id",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/EditStaticContent")),
  },
  {
    guard: true,
    exact: true,
    path: "/view-static-content/:id",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/ViewStaticContent")),
  },
  {
    guard: true,
    exact: true,
    path: "/view-faq-content/",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/FaqTable")),
  },
  {
    guard: true,
    exact: true,
    path: "/add-faq/",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/AddFaq")),
  },
  // {
  //   guard: true,
  //   exact: true,
  //   path: "/blog-management/",
  //   layout: DashboardLayout,
  //   element: lazy(() => import("src/views/pages/Static/BlogManagement")),
  // },
  {
    guard: true,
    exact: true,
    path: "/team-management/",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/TeamManagement")),
  },
  {
    guard: true,
    exact: true,
    path: "/add-team/",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/AddTeam")),
  },
  {
    guard: true,
    exact: true,
    path: "/blog-management/",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/BlogManagement")),
  },
  {
    guard: true,
    exact: true,
    path: "/add-blog/",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/AddBlog")),
  },
  {
    guard: true,
    exact: true,
    path: "/view-banner-management/",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/BannerTable")),
  },
  {
    guard: true,
    exact: true,
    path: "/add-banner/",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/AddBanner")),
  },
  {
    guard: true,
    exact: true,
    path: "/add-static-content/:id",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Static/AddStaticContent")),
  },
  {
    guard: true,
    exact: true,
    path: "/account",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Account/Account")),
  },
  {
    guard: true,
    exact: true,
    path: "/edit-user-profile",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Account/EditProfile")),
  },

  {
    guard: true,
    exact: true,
    path: "/bank-overview",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/BankInfo")),
  },
  {
    guard: true,
    exact: true,
    path: "/change-user-password",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Account/ChangePassword")),
  },
  {
    exact: true,
    path: "/404",
    element: lazy(() => import("src/views/errors/NotFound")),
  },
];

export default routes;
