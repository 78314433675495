import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { typography } from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiIconButton: {
      root: {
        color: "#e1e1e1",
      },
    },
    MuiTableContainer: {
      root: {
        borderRadius: "10px",
        border: "1px solid rgba(255, 255, 255, 0.1)",
      },
    },
    MuiTable: {
      root: {
        background: "rgba(14, 14, 17, 0.5)",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "transparent",
        fontSize: "14px",
        whiteSpace: "pre",
        color: "#fff",
        padding: "14px 12px",
        fontWeight: "400",
      },
      body: {
        color: "#fff",
      },
      head: {
        padding: "16px 12px",
        color: "#fff",
        fontWeight: 600,
      },
    },
    MuiTableRow: {
      root: {
        borderBottom: "1px solid rgba(24, 25, 29, 1)",
      },
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#fff",
        "&:hover": {
          color: "#fff",
        },
        "& svg": {
          opacity: "1 !important",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "5px",
        fontFamily: "'Roboto Condensed', sans-serif",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        "& svg": {
          color: "#8C8C8C",
          fontSize: "20px",
        },
      },
      notchedOutline: { borderColor: "transparent" },
      input: {
        // borderRadius: "10px",
        fontWeight: "300",
        color: "#fff",
        padding: "15px 13px",
        "&:-webkit-autofill": {
          "-webkit-background-clip": "text !important",
          // transitionDelay: "9999s",
          "caret-color": "transparent",
          "-webkit-box-shadow": "0 0 0 100px transparent inset",
          "-webkit-text-fill-color": "#fff",
        },
        "&:-internal-autofill-selected": {
          color: "#fff",
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#18191D",
        border: "1px solid rgba(255, 255, 255, 0.1)",
      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation1: {
        backgroundColor: "#222325",
        // borderRadius: "20px",
        // padding: "40px 40px 30px",
        "@media(max-width:767px)": {
          // padding: "25px",
        },
      },
      elevation2: {
        backgroundColor: "#0E0E1180",
        borderRadius: "8px",
        padding: "25px",
        "@media(max-width:767px)": {
          padding: "20px",
        },
      },
      elevation3: {
        padding: "20px",
        background: "#0f1012",
        borderRadius: "20px",
        position: "relative",
        boxShadow: "0px 0px 4px 1px rgb(255 255 255)",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiMenuItem: {
      root: {
        color: "#FFFFFF",
        fontSize: "14px",
        paddingLeft: "8px",
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
        marginBottom: "10px",
        width: "100%",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        // "&.Mui-checked": { color: "#10C6FF" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: "0px !important",
        color: "red",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      borderRadius: "20px",
      paperScrollPaper: {
        Width: 550,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
      paperFullWidth: {
        background: "#18191D",
        borderRadius: "20px",
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        borderRadius: "20px",
      },
    },
    MuiInputBase: {
      input: {
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: 14,
        fontWeight: 400,
      },
    },

    MuiDivider: {
      root: {
        backgroundColor: "rgba(112, 112, 112)",
      },
      vertical: {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      },
    },
    MuiAutocomplete: {
      paper: {
        padding: 0,
      },
      option: {
        fontFamily: "Roboto Condensed",
        fontSize: "12px !important",
        fontWeight: "400 !important",
        lineHeight: "18px !important",
        letterSpacing: "0px !important",
        textAlign: "left !important",
      },
      inputRoot: {
        "& .MuiAutocomplete-input": {
          padding: "5.5px 3px !important",
        },
      },
    },
    MuiButton: {
      containedSecondary: {
        color: "#fff",
        height: "40px",
        padding: "8px 20px",
        fontSize: "16px",
        // border: "1px solid #E1E1E1",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        fontWeight: "500",
        lineHeight: "20px",
        fontFamily: "'Clash Display'",
        borderRadius: "4px",
        // backgroundColor: "#F2F2F2",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.09)",
          // border: "1px solid #10C6FF",
          boxShadow: "none !important",
          // backgroundColor: "#10C6FF",
        },

        "& .Mui-disabled": {
          color: "transparent !important",
          backgroundColor: "transparent !important",
        },
      },

      "& .Mui-disabled": {
        color: "transparent !important",
        backgroundColor: "transparent !important",
      },
      MuiDisabled: {
        color: "transparent !important",
        backgroundColor: "transparent !important",
      },
      containedPrimary: {
        color: "#000",
        height: "40px",
        padding: "8px 20px",
        fontSize: "16px",
        backgroundColor: "#fff",
        // border: "1px solid #10C6FF",
        fontWeight: "500",
        lineHeight: "20px",
        fontFamily: "'Clash Display'",
        whiteSpace: "pre",
        borderRadius: "4px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
        // backgroundColor: "#10C6FF",
        "@media(max-width:599px)": {
          fontSize: "13px",
        },
        "&:hover": {
          color: "#000",
          // border: "1px solid #E1E1E1",
          // backgroundColor: "rgba(255, 255, 255, 0.05)",
          backgroundColor: "#E1E1E1",
        },
        "& .Mui-disabled": {
          color: "transparent !important",
          backgroundColor: "none !important",
        },
      },
      contained: {
        borderRadius: "50px",
        fontWeight: 600,
        padding: "5px 19px",
        boxShadow: "none !important",
        "& .Mui-disabled": {
          color: "transparent !important",
          backgroundColor: "none !important",
        },
      },
      outlinedPrimary: {
        color: "#000",
        border: "1px solid #10C6FF",
        padding: "5px 25px",
        fontWeight: "500",
        borderRadius: "50px",
        fontSize: "13px",
        "&:hover": {
          backgroundColor: "#E1E1E1",
          color: "#000",
        },
        "& .Mui-disabled": {
          color: "transparent !important",
          backgroundColor: "none !important",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    PrivateTabIndicator: {
      root: {
        backgroundColor: "transparent !important",
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#26252E",
          borderRadius: "8px !important",
        },
      },
    },

    MuiTypography: {
      subtitle1: {
        // color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },

    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "#fff",
      },
      iconButton: {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        color: "#fff",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      },
    },
    // MuiPickersCalendar: {
    //   // MuiPickersDay: {
    //   //   day: {
    //   //     color: "#fff",
    //   //   },
    //   // },
    //   // daySelected: {
    //   //   p: {
    //   //     color: "#000",
    //   //   },
    //   // },
    //   "& .dayDisabled": {
    //     "& .MuiTypography-body2": {
    //       color: "#8d8989",
    //     },
    //   },
    //   "& .daySelected": {
    //     backgroundColor: "#1c1c1c",
    //     "& .MuiTypography-body2": {
    //       color: "#000",
    //     },
    //   },
    // },
    // MuiPickersCalendar: {
    //   "& .MuiPickersDay-root": {
    //     // Default day styling
    //     "& .MuiTypography-root": {
    //       color: "#fff",
    //     },
    //   },
    //   "& .MuiPickersDay-root.Mui-selected": {
    //     // Selected day styling
    //     backgroundColor: "#1c1c1c",
    //     "& .MuiTypography-root": {
    //       color: "#000",
    //     },
    //   },
    //   "& .MuiPickersDay-root.Mui-disabled": {
    //     // Disabled day styling
    //     "& .MuiTypography-body2": {
    //       color: "#8d8989",
    //     },
    //   },
    // },
    MuiPickersSlideTransition: {
      "& .MuiPickersCalendar-week": {
        "& .MuiPickersDay-daySelected": {
          // Default day styling
          "& .MuiTypography-root": {
            color: "#000 !important",
          },
        },
      },
    },
    MuiPickersCalendar: {
      "& .MuiPickersDay-root": {
        // Default day styling
        "& .MuiTypography-root": {
          color: "#fff",
        },
      },
      "& .MuiPickersDay-root.Mui-selected": {
        // Selected day styling with background and text color
        backgroundColor: "#1c1c1c !important", // Ensure the background is dark
        color: "#fff !important", // Ensure the text color is white
        "& .MuiTypography-root": {
          color: "#fff !important", // Ensure the typography text is white
        },
      },
      "& .MuiPickersDay-root.Mui-disabled": {
        // Disabled day styling
        "& .MuiTypography-body2": {
          color: "#8d8989",
        },
      },
    },

    // MuiPickersCalendar: {
    //   day: {
    //     typography: {
    //       color: "#fff",
    //     },
    //   },
    //   daySelected: {
    //     backgroundColor: "#1c1c1c",
    //     typography: {
    //       color: "#fff",
    //     },
    //   },
    //   dayDisabled: {
    //     typography: {
    //       color: "#8d8989",
    //     },
    //   },
    // },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "rgba(0, 0, 0)",
        borderRadius: "20px",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "#fff",
      },
      toolbarBtnSelected: {
        color: "#fff",
        marginTop: "4px",
      },
    },
    MuiTab: {
      root: {
        backgroundColor: "#fff",
        color: "#000 !important",
        borderRadius: "4px",
        height: "40px",
        padding: "4px 12px",
        margin: "0 5px",
        fontSize: "16px",
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#1976d2", // Customize the toolbar background color
        },
      },
    },
    MuiPickersToolbarText: {
      styleOverrides: {
        root: {
          // color: "#ffffff", // Customize the toolbar text color
          fontSize: "1.5rem", // Customize the font size
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: "#1976d2", // Customize the day text color
        },
        today: {
          borderColor: "#ff4081", // Customize the color for today's date
        },
        selected: {
          backgroundColor: "#ff4081", // Customize the background color for selected date
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#1976d2", // Customize the color of icon buttons
        },
      },
      label: {
        // color: "#fff",
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#1976d2", // Customize the input text color
          "&.Mui-disabled": {
            color: "rgba(255, 255, 255, 0.6)",
          },
        },
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     notchedOutline: {
    //       borderColor: "#1976d2", // Customize the border color of the input
    //     },
    //   },
    // },
    MuiPickersClock: {
      styleOverrides: {
        pin: {
          backgroundColor: "#ff4081", // Customize the clock pin color
        },
      },
    },
    MuiPickersClockNumber: {
      styleOverrides: {
        root: {
          color: "#1976d2", // Customize the clock number color
        },
        selected: {
          color: "#ffffff", // Customize the color of the selected clock number
        },
      },
    },
    // .MuiPickersBasePicker-pickerView
    MuiPickersBasePicker: {
      pickerView: {
        styleOverrides: {
          root: {
            color: "#1976d2", // Customize the color of the calendar icon
          },
        },
        maxWidth: "100%",
        minWidth: "100%",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Roboto', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#ffffff",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#FFFFFFa6",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff0010",
      dark: "#ff0010",
      light: "#ffe9e6",
    },
    text: {
      primary: "#8e949d",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
